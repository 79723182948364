@import "../_variables";

.discover {
  &-section {
    &-optimize {
      display: flex;
      text-align: center;
      justify-content: space-around;
      align-items: center;
      padding: 46px;
      background: $color-white 0 0 no-repeat padding-box;
      box-shadow: 2px 2px 8px #00000029;
      border-radius: 20px;
      margin: 60px auto;
      margin-top: -87px;
      position: relative;

      &-item {
        width: calc(33.33333% - 6px);

        &-image {
          max-width: 150px;
          margin-bottom: 20px;
        }

        &-title {
          font-size: 24px;
          line-height: 30px;
          color: $color-blue;
          font-weight: bold;

          &-colored {
            color: $color-green;
          }
        }
      }

      &-separator {
        height: 190px;
        width: 3px;
        background-color: $color-green;
        margin: 0 20px;
      }
    }

    @media screen and (max-width: 1199px) {
      &-optimize {
        background: none;
        box-shadow: none;
        margin-top: 60px;
        width: 100%;
        padding: 20px;

        &-item-title {
          font-size: 24px;
          line-height: 30px;
        }

        &-separator {
          display: none;
        }
      }
    }

    @media screen and (max-width: 992px) {
      &-optimize {
        flex-wrap: wrap;
        justify-content: center;

        &-item {
          width: 50%;
          margin-bottom: 20px;

          &:nth-child(5) {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      &-optimize-item {
        width: 100%;

        &-title {
          font-size: 20px;
        }
      }
    }

    &-steps {
      text-align: center;
      padding: 5rem 0;
      
      @media screen and (max-width: 992px) {
        padding: 2rem 0;
      }

      &-presentation {
        h2 {
          margin-bottom: 5px !important;

          @media screen and (max-width: 992px) {
            margin-bottom: 20px !important;
          }

          &-colored {
            color: $color-green;
          }
        }

        p {
          font-size: 24px;
          line-height: 30px;
          color: $color-grey;
          margin-bottom: 20px;
          font-weight: 500;

        }
      }

      &-item {
        &-image {
          max-width: 320px;
          margin-bottom: 20px;

          @include media-breakpoint-down(lg) {
            max-width: 250px;
          }
        }

        &-title {
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          font-family: $font-family;
          color: $color-blue;
        }

        &-subtitle {
          font-size: 16px;
          line-height: 20px;
          color: $color-grey;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 2.5rem 0;

        &-presentation {
          p {
            font-size: 18px;
            line-height: 23px;
          }
        }

        &-item {
          &-image {
            width: 80%;
          }

          &-title {
            font-size: 20px;
          }
        }
      }
    }

    &-rental {
      background: #f5f5f5;
      border-radius: 20px;
      margin: 60px auto;
      padding-top: 3rem;

      @include media-breakpoint-down(md) {
        padding: 20px;
      }

      &-content {
        &-block {
          &-subtitle {
            font-weight: bold;
            font-size: 22px;
            line-height: 28px;
            color: $color-green;
            text-align: left;
            margin-bottom: 5px;

            svg {
              margin-right: 10px;
            }
          }

          &-text {
            font-size: 16px;
            line-height: 20px;
            color: $color-grey;
            text-align: left;
            padding-left: 38px;
          }

          &:last-child {
            padding-bottom: 3rem;
          }
        }

        &-title {
          &-colored {
            color: $color-green;
          }
        }
      }

      @include media-breakpoint-down(xl) {
        svg {
          display: none;
        }

        &-content-block {
          &-text {
            padding-left: 0;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        &-content-block {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }

    &-price {
      text-align: center;
      padding: 3rem 0;

      .row div:nth-child(2) {
        .discover-section-price-cards {
          background: #effff1;
          box-shadow: 2px 2px 8px #00000029;
          border: 3px solid #ed5252;
          border-radius: 20px;
          text-align: center;
          margin: -50px 0;
          height: calc(100% + 50px);

          @include media-breakpoint-down(md) {
            margin: 0;
            height: 100%;
          }

          &-title {
            margin-top: 70px !important;
          }
        }
        .discover-section-price-cards-separator {
          border: 1px solid $color-green;
          margin: 20px 30px;
          opacity: 1;
        }
      }

      &-presentation {
        &-subtitle {
          font-size: 24px;
          line-height: 30px;
          font-weight: 500;
          color: $color-grey;

          @include media-breakpoint-down(xl) {
            padding: 0 200px;
          }

          @include media-breakpoint-down(lg) {
            padding: 0;
          }

          @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      &-cards {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $color-white;
        box-shadow: 2px 2px 8px #00000029;
        border: 3px solid transparent;
        height: 100%;
        border-radius: 20px;
        text-align: center;
        position: relative;
        padding: 10px 0;

        &-shape {
          position: absolute;
          width: 90%;
          padding: 15px 0;
          margin: -35px 5% 0;
          color: $color-white;
          text-transform: uppercase;
          font-size: 25px;
          line-height: 30px;
          font-weight: 700;

          &-title {
            width: 100%;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
          }

          @media screen and (max-width : 1600px) {
            &-title {
              top: 20px;
            }
          }

          @media screen and (max-width : 1200px) {
            img {
              max-height: 100px;
            }
          }

          @media screen and (max-width : 380px) {
            &-title {
              top: 20px;
              font-size: 16px;
            }
          }
        }

        @media screen and (max-width : 380px) {
          &-shape {
            font-size: 20px;
          }
        }
        @media screen and (max-width : 1600px) {
          &-shape {
          font-size: 19px;
          }
        }

        &-number {
          color: $color-green;
          font-size: 27px;
          line-height: 29px;
          padding: 20px 0;

          &-special {
            position: relative;
            display: inline-block;
            text-decoration: none;
            font-size: 53px;
            line-height: 29px;
            font-weight: 800;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -80%;
              width: 110%;
              height: 2px;
              background-color: #ed5252;
              transform-origin: left bottom;
              transform: rotate(-25deg);
            }
          }
        }

        &-promo {
          color: #ed5252;
          font-size: 27px;
          line-height: 29px;
          padding: 20px 0;
          margin-top: -40px;

          &-special {
            position: relative;
            display: inline-block;
            text-decoration: none;
            font-size: 53px;
            line-height: 29px;
            font-weight: 800;
          }
        }

        &-title {
          margin: 0 auto !important;
          padding-top: 15px;
          font-size: 35px;

          &-popular {
            margin-top: 70px !important;

            @include media-breakpoint-down(sm) {
              margin-top: 30px !important;
            }
          }
        }

        &-separator {
          border: 1px solid $color-grey-light;
          margin: 20px 30px;
          opacity: 1;
        }

        &-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
          margin-top: 50px;
          margin-bottom: 50px;
          padding: 0 10px;
        }

        .solu-btn-outline {
          border: 2px solid #ed5252;
          border-radius: 100px;
          text-align: center;
          font-size: 18px;
          line-height: 21px;
          font-family: $font-family;
          font-weight: bold;
          color: #ed5252;
          padding: 10px 24px;
          text-decoration: none;

          &:hover {
            background-color: #ed5252;
          }

          @include media-breakpoint-down(sm) {
            font-size: 15px;
          }

          &:not(.no-arrow):after {
            content: revert;
            margin-left: revert;
            transition: revert;
          }
        }

        &-list {
          list-style: none;
          padding: 0;
          margin: 0;
          font-size: 18px;
          line-height: 23px;
          color: #2e3d44;
          padding: 0 10px;

          &-item {
            margin: 10px 0;
          }
        }
      }
    }

    &-cards-second {
      background-color: $color-white;
      position: relative;
      border-radius: 20px;
      padding: 60px 30px 30px;
      text-align: center;
      box-shadow: 1px 0px 7px #00000029;

      &-number {
          position: absolute;
          top: 25px;
          right: 25px;
          color: $color-green;
          opacity: 0.55;
          font-weight: bold;
          font-size: 50px;
          line-height: 59px;
          font-family: $font-family;
      }

      &-img {
          width: 180px;
          height: 180px;
          margin-bottom: 15px;
      }

      &-free {
        position: absolute;
        top: -20px;
        left: -20px;
      }

      &-title {
          color: $color-blue;
          font-size: 24px;
          line-height: 26px;
          font-weight: bold;
          text-align: center;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        &-cards-second {
            padding: 30px;

            &-number {
                top: 15px;
                right: 15px;
            }

            &-title {
                font-size: 19px;
                line-height: 21px;
            }
        }
    }

    @media screen and (max-width: 991px) {
        &-cards-second {
            margin-bottom: 30px;
        }
    }
  }
}
