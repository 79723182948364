@import "./_variables";
@import "./_mixins";
@import "./libraries/_buttons";
@import "./libraries/_status";


body {
    font-family: $font-family !important;
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 700;
    }

    h1 {
        font-size: 55px;
    }

    h2 {
        color: $color-blue;
        font-size: 40px;
        line-height: 45px;
        font-weight: 700;
        margin-bottom: 43px;

        span {
            color: $color-green;
        }

        @media screen and (max-width: 1199.98px) {
            font-size: 38px  !important;
            line-height: 43px  !important;
            margin-bottom: 33px  !important;
        }

        @media screen and (max-width: 575.98px) {
            font-size: 30px !important;
            line-height: 34px  !important;
            margin-bottom: 23px  !important;
        }
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }
}

p {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 400;
}

a {
    cursor: pointer;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.clear {
    clear: both;
}

.bigcontainer {
    @include custom-container(
        $bigcontainer-maxwidth-xxl,
        $bigcontainer-maxwidth-xl,
        $bigcontainer-maxwidth-lg,
        $bigcontainer-maxwidth-md,
        $bigcontainer-maxwidth-sm,
        $bigcontainer-maxwidth-xs
    );
}

.mediumcontainer {
    @include custom-container(
        $mediumcontainer-maxwidth-xxl,
        $mediumcontainer-maxwidth-xl,
        $mediumcontainer-maxwidth-lg,
        $mediumcontainer-maxwidth-md,
        $mediumcontainer-maxwidth-sm,
        $mediumcontainer-maxwidth-xs
    );
}

.subtitle {
    font-size: 24px !important;
}

.copyright {
    font-size: 14px !important;
}

.button {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.modal {
    z-index: 9999999 !important;
}

.rmdp-day.rmdp-today span {
    background-color: transparent !important;
}

.input-range__label {
    font-family: "Outfit", sans-serif !important;
}

@media screen and (min-width: 1200px) {
    .hide-desktop {
        display: none !important;
    }
}

@media screen and (min-width: 992px) {
    .hide-desktop-xs {
        display: none !important;
    }
}

@media (min-width: 576px) and (max-width: 1199.98px){
    .hide-tablet {
        display: none !important;
    }
}

@media screen and (max-width: 991.98px) {
    .hide-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 575.98px) {
    .hide-mobile-xs {
        display: none !important;
    }
}

//Pour rattraper un défaut de marge crée par l'augmentation $grid-gutter-width: 2.5rem;
@media screen and (max-width: 575.98px) {
    .row {
        margin-right: calc(-0.5 * 1.5rem);
        margin-left: calc(-0.5 * 1.5rem);
    }
    .row > * {
        padding-right: calc(1.5rem * 0.5);
        padding-left: calc(1.5rem * 0.5);
    }
}
